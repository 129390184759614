<script setup lang="ts">
const { t } = useT();
const isGuest = useIsGuest();
const { data: appInit } = useAppInitData();

const props = withDefaults(
	defineProps<{
		textSize?: number;
		lightTheme?: boolean;
		countText?: string;
		textAlignment?: "left" | "center";
	}>(),
	{
		textSize: 8,
		textAlignment: "left"
	}
);

const { BALLS, counterBalls, collectedBalls } = useBingoData({
	immediate: !isGuest.value && appInit.value?.bingo?.isActive
});

const ballsArray = computed(() =>
	Array.from({ length: BALLS }, (_, index) => (index < collectedBalls.value ? getRandom({ min: 1, max: 90 }) : null))
);

const shuffle = (array: { light: string; dark: string }[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const shuffledColors = computed(() => shuffle(BINGO_COLORS));
const countTitle = computed(() => props.countText ?? t("Collected"));
</script>

<template>
	<div class="progress-wrapper">
		<div :class="['progress', { lightTheme }]">
			<template v-for="(ball, index) in ballsArray" :key="index">
				<MBingoBall
					v-if="ball"
					:id="index"
					:key="`ball-${index}`"
					:light="shuffledColors[index]?.light"
					:dark="shuffledColors[index]?.dark"
					:number="ball"
				/>
			</template>
		</div>
		<AText class="text" :size="textSize" :modifiers="[textAlignment, 'bold', 'wide']" as="div">
			{{ counterBalls }}
			<AText class="text-conakry" :size="textSize" :modifiers="['normal', 'uppercase']">{{ countTitle }}</AText>
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.progress-wrapper {
	width: 100%;

	.progress {
		margin-bottom: 2px;
		width: 146px;
		border-radius: 20px;
		background: var(--cacem);
		display: flex;
		align-items: center;
		height: 20px;
		gap: 2px;

		&.lightTheme {
			background: var(--changshu);
		}

		& > * {
			position: relative;
			width: 20px;
			max-width: 20px;
			height: 20px;
			max-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: -7px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}
</style>
